// this vendor package is used to package dependencies that should be available everywhere (including the login page).
export {default as imagesLoaded} from '../node_modules/imagesloaded/imagesloaded.js';
export {parse as yaml_parse} from '../node_modules/yaml/browser/index.js';
export {default as Flipping} from '../node_modules/flipping/dist/flipping.js'
export {default as anime} from '../node_modules/animejs/lib/anime.es.js';
export {default as fitty} from "../node_modules/fitty/dist/fitty.min.js";
export {default as merge} from '../node_modules/lodash-es/merge.js';
export {default as get} from '../node_modules/lodash-es/get.js';
export {default as debounce} from '../node_modules/lodash-es/debounce.js'
export {default as set} from '../node_modules/lodash-es/set.js';
export {default as partial} from '../node_modules/lodash-es/partial.js';
export {default as isEqual} from '../node_modules/lodash-es/isEqual.js';
export {default as round} from '../node_modules/lodash-es/round.js';
export {default as escape} from '../node_modules/lodash-es/escape.js';
export {default as uniqBy} from '../node_modules/lodash-es/uniqBy.js';
export {default as flatten} from '../node_modules/lodash-es/flatten.js';
export {default as invoke} from '../node_modules/lodash-es/invoke.js';
export {default as orderBy} from '../node_modules/lodash-es/orderBy.js';
export {default as intersection} from '../node_modules/lodash-es/intersection.js';
export {default as isNil} from '../node_modules/lodash-es/isNil.js';
export {default as isEmpty} from '../node_modules/lodash-es/isEmpty.js';
export {default as values} from '../node_modules/lodash-es/values.js';
export {default as fromPairs} from '../node_modules/lodash-es/fromPairs.js';
export {default as pickBy} from '../node_modules/lodash-es/pickBy.js';
export {default as identity} from '../node_modules/lodash-es/identity.js';
export {default as cloneDeep} from '../node_modules/lodash-es/cloneDeep.js';
export {default as size} from '../node_modules/lodash-es/size.js';
export {default as has} from '../node_modules/lodash-es/has.js';
export {default as trimEnd} from '../node_modules/lodash-es/trimEnd.js';
export {default as unique} from '../node_modules/lodash-es/uniq.js';
export {default as Split} from '../node_modules/split.js/dist/split.es.js';
export {Decimal} from '../node_modules/decimal.js/decimal.js';

// morphdom is used in the current component framework.
export {default as morphdom} from '../node_modules/morphdom/dist/morphdom-esm.js';

export {popperGenerator, detectOverflow, createPopper, createPopperLite} from "../node_modules/@popperjs/core/dist/esm/index.js";

import domtastic from '../node_modules/domtastic/dist/domtastic.min.js';

domtastic.fn.click = function (handler) {
  return !!this.length ? this.off('click').on('click', handler) : this;
}
export {domtastic as $}
