import {Component, render} from 'preact';
import {html} from 'htm/preact';

import {Modal} from '../vendor/bootstrap.min.js';
import {$} from '../vendor/common.min.js';

const logo = new URL('/node_modules/acceladapt-online-lib/assets/AccelAdaptLogoHoriz.png', import.meta.url);

export class AuthFlowDialog extends Component {
  shouldComponentUpdate = (_, nextState) => {
    // show/hide spinners and error message.
    if (this.base) {
      if (nextState.error_message) {
        $(this.base).find('.triangle-spinner').addClass('d-none');
        $(this.base).find('.alert').text(nextState.error_message).removeClass('d-none');
      }else if(this.state.error_message && !nextState.error_message){
        $(this.base).find('.alert').addClass('d-none');
      }
    }

    return false
  };

  constructor(props, context) {
    super(props, context);
    this.state = {error_message: null};
    this.modal = null;
    this.whenMount = new Promise((resolve) => {
      this._resolveMount = resolve
    });
  }

  componentDidMount() {
    this._resolveMount();
  }

  async request_show() {
    await this.whenMount
    this.modal_el = this.base
    if ($(this.modal_el).hasClass('show')) {
      return
    }
    if (!this.modal) {
      this.modal = new Modal(this.modal_el, {backdrop: 'static', keyboard: false})
    }
    this.modal.show();
  }

  hide() {
    if (!!this.modal) {
      this.modal.hide()
    }
    $(this.modal_el).removeClass('show');
  }


  render() {
    const portal_support = window.auth_manager.has_portal_support;
    return html`
      <div id='auth-or-arcgis-dialog' class='modal bg-white' tabindex='-1' style='z-index: 1000000;' role='dialog' data-backdrop='static' data-keyboard='false'>
        <div class='modal-dialog modal-dialog-centered' style='max-width: ${portal_support ? '47' : '26'}rem;' role='document'>
          <div class='modal-content'>
            <div class='modal-header'>
              <button type='button' class='btn-close logout-button' title='Logout / Switch accounts' style='font-size:1rem; background: white; line-height: 1rem;'
                      aria-label='Logout / Switch accounts' onclick="${() =>
                  window.auth_manager.logout()
              }"><i class='fa fa-sign-out'></i>
              </button>
            </div>
            <div class='modal-body'>
              <div class="d-flex justify-content-center px-3 my-2"><img src="${logo}" alt="AccelAdapt" class="img-fluid" style="width: 30rem;"/></div>
              <div class="row my-2">
                <div class="col col-md-${portal_support ? '6' : '12'} panel-1-border d-flex flex-column px-2 py-3 px-md-3 py-md-5">
                  <div>

                    <h5 class="text-center font-weight-light">Sign in with AccelAdapt account</h5>
                    <div class="d-flex justify-content-center my-3">
                      <button id='auth-login-start' class='btn btn-primary' style="min-width: 6rem;" onclick=${() => {
                        window.auth_manager.auth_flow_cognito_login_start(false)
                        $(this.base).find('#auth-login-start .triangle-spinner').removeClass('d-none');
                      }}>Sign in
                        <div class="triangle-spinner float-end user-update-spinner d-none" style="margin: 0.15rem -0.25rem 0 0.5rem;"><span></span><span></span><span></span></div>
                      </button>
                    </div>
                    <div class="text-center text-muted">Need an account?
                      <button class="btn btn-link btn-sm shadow-none border-0 p-0 ms-1" style="vertical-align: baseline" id="auth-login-start-signup"
                              onclick=${() => {
                                window.auth_manager.auth_flow_cognito_login_start(true);
                                $(this.base).find('#auth-login-start .triangle-spinner').removeClass('d-none');
                              }}>Sign up
                      </button>
                    </div>
                  </div>
                </div>
                ${portal_support ? html`
                  <div class="vr-or  d-none d-md-flex"><span>or</span></div>
                  <div class="hr-or  d-flex d-md-none"><span>or</span></div>
                  <div class="col col-md-6 panel-2-border  d-flex flex-column px-2 py-3 px-md-3 py-md-5">
                    <h5 class="text-center font-weight-light">Sign in with <i>ArcGIS Online</i></h5>
                    <div class="d-flex justify-content-center my-3">
                      <button id='portal-login-start' class='btn btn-secondary' style="min-width: 6rem;" onclick=${
                          () => {
                            window.auth_manager.auth_flow_portal_login_start();
                            $(this.base).find('#portal-login-start .triangle-spinner').removeClass('d-none');
                          }}>Continue
                        <div class="triangle-spinner float-end user-update-spinner d-none" style="margin: 0.15rem -0.25rem 0 0.5rem;"><span></span><span></span><span></span></div>
                      </button>
                    </div>
                  </div>
                ` : ''}
              </div>
              <div id="terms_wrap" class="text-center text-muted">By clicking to continue, you agree to our <a href="https://acceladapt.com/terms/" target="_blank" class="text-muted">Terms
                of Service</a> and <a href="https://acceladapt.com/privacy/" target="_blank" class="text-muted">Privacy Policy</a>.
              </div>
              <div class="alert alert-danger py-2 my-2 ${!this.error_message ? 'd-none' : ''}" role="alert">${this.error_message || ''}</div>
            </div>
          </div>
        </div>
      </div>
    `;
  }
}
