// import AuthRequestAccessDialog from '../components/auth_request_access_dialog.js';
import * as jose from '../vendor/jose.min.js';
import {createRef, render} from "preact";
import {html} from "htm/preact";
import {AuthFlowDialog} from '../components/auth_flow_dialog.js';

const AuthRequestAccessDialog = class {
}
const _cognito_public_keys = {}

/**
 * The AuthManager handles user login and logout flows, and all of the dialogs involved. Note that Oauth2.0 Authorization Grant flow with PKCE is used.
 *
 * Cognito-based authentication happens in 3 steps:
 * 1. Create a new oauth_code_challenge key and open a popup window with the Cognito-hosted UI
 * 2. When the user signs-in, Cognito redirects the user to oauth-callback.html with an authorization_code and a code_verifier
 * 3. Exchange the authorization_code/code_verifier for Cognito JWT tokens AND Cloudfront cookies
 * 4. Reload the page and force cache busting.
 *
 *
 * If anything goes wrong with this, just take it down to the authentication station.
 *
 * See also: https://docs.google.com/presentation/d/1gFmrW4RwuDvkVh7OJb1hMnKpsUwI4_SGU9dmrwhLS4M/edit#slide=id.gb188a48a73_0_387
 * See also: https://aws.amazon.com/blogs/security/how-to-add-authentication-single-page-web-application-with-amazon-cognito-oauth2-implementation/
 */
export default class AuthManager {
  /**
   * Convenience method denoting the user has viewer permissions.
   * @return {boolean}
   */
  get user_is_viewer() {
    if (!this.project_id) {
      return false;
    }
    return !!this._user_attributes && 'user_is_viewer' in this._user_attributes && this._user_attributes.user_is_viewer;
  }

  /**
   * Convenience method denoting the user has manager permissions.
   * @return {boolean}
   */
  get user_is_manager() {
    if (!this.project_id) {
      return false;
    }
    return !!this._user_attributes && 'user_is_manager' in this._user_attributes && this._user_attributes.user_is_manager;
  }

  /**
   * Convenience method denoting the user has pro permissions.
   * @return {boolean}
   */
  get user_is_pro() {
    return !!this._user_attributes && 'user_is_pro' in this._user_attributes && this._user_attributes.user_is_pro;
  }

  /**
   * Resolves when user has logged in and viewer access has been confirmed for the first time.
   *
   * @returns {Promise}
   */
  get when_authed() {
    if (!this._when_auth) {
      this._when_auth = new Promise((resolve) => {
        this._resolve_when_auth = resolve
      });
    }
    return this._when_auth;
  }

  constructor({
                project_id,
                application_url,
                application_client_id,
                application_user_pool_id,
                application_identity_pool_id,
                project_title,
                portal_application_id,
                portal_client_id,
                portal_url,
                portal_group
              }) {
    this.project_id = project_id;
    this.application_client_id = typeof window.application_client_id !== "undefined" ? window.application_client_id : application_client_id;
    this.application_user_pool_id = typeof window.application_user_pool_id !== "undefined" ? window.application_user_pool_id : application_user_pool_id;
    this.application_identity_pool_id = typeof window.application_identity_pool_id !== "undefined" ? window.application_identity_pool_id : application_identity_pool_id;

    this.application_user_pool_region = (application_user_pool_id || '').split('_')[0];
    this.application_url_host = new URL(window.location).host
    this.application_url = `https://${this.application_url_host}`;
    this.application_api_url = `https://${this.application_url_host}/api${!!this.project_id ? +'/' + this.project_id : ''}`;
    this.auth_url = `https://auth.dev2-pro.acceladapt.com`;
    this._filter_cookie = (c) => c;

    console.log("URL Hostname:", (new URL(window.location)).hostname, (new URL(window.location)).hostname.indexOf('localhost') !== -1);

    if ((new URL(window.location)).hostname.indexOf('staging') !== -1 || (new URL(window.location)).hostname.indexOf('localhost') !== -1) {

      this.application_api_url = `https://localhost:44357/api${!!this.project_id ? '/' + this.project_id : ''}`
      this.auth_url = `https://auth.${new URL(application_url).hostname}`;

      console.log(`[Dev Mode] application_api_url:${this.application_api_url} auth_url:${this.auth_url}`);

      this._filter_cookie = (c) => c.replace(/Domain=.+?;/, 'Domain=localhost;')
    }

    this.portal_params = {
      project_id,
      project_url: application_url,
      project_title,
      portal_application_id,
      portal_client_id,
      portal_url,
      portal_group,
      application_api_url: this.application_api_url
    }
    this.has_portal_support = !!this.portal_params.portal_application_id;
    this.has_cognito_support = !!this.application_client_id;
    /** @type {{id_token, access_token, refresh_token}|null} */
    this.cognito_tokens = null; // holds active tokens
    /** @type {{user_is_viewer: boolean, user_email: null, user_username: null, user_is_manager: boolean, user_nicename: null, user_is_pro: boolean}|null}  */
    this._user_attributes = null;
    this._load_tokens(); // attempt to restore tokens from local storage.
    this.auth_flow = 'cognito'; // 'cognito' or 'portal'

    this._when_auth = null;
    this._resolve_when_auth = null;

    // when_authorization_code is used to return an authorization_code from the Cognito OAuth popup window.
    this.when_authorization_code = null;
    this._resolve_authorization_code = null;

    //watchers
    this.oauth_window_status_watcher = null;
    this.refresh_watcher = null;



    let searchParams = (new URLSearchParams(window.location.search));
    // b is used to cache-bust browsers and cloudfront consistently, but once we load we should clear it.
    if (searchParams.get('b')) {
      searchParams.delete("b");
      window.history.replaceState(window.history.state, window.document.title, window.location.pathname + (searchParams.toString() ? '?' + searchParams.toString() : '') + (window.location.hash ? '#' + window.location.hash.replace(/^#/, "") : ''));
    }

    // invite_code is used when associating users to groups to provide access to the project.
    this.invite_code = null;
    if (searchParams.get('invite_code')) {
      this.invite_code = searchParams.get("invite_code");
    }

    //dialogs
    this.user_invite_dialog = null;
    this.auth_request_access_dialog = null;

    //callbacks
    this.auth_callbacks = [];
    //bypass for dev purposes
    if (!this.has_cognito_support && !this.has_portal_support) {
      this.when_authed // call to make sure it's inited
      this._resolve_when_auth()
      return
    }

    this.request_auth();
  }


  /**
   * Determine if a refresh is needed soon.
   *
   * @param access_token_expires shortcut
   * @return {Promise<boolean>}
   */
  async needs_refresh(access_token_expires = null) {
    // Suppress refresh if we aren't using the cognito flow, or otherwise a refresh would fail.
    if (this.auth_flow !== 'cognito' || !this.cognito_tokens || !this.cognito_tokens.refresh_token) {
      return false;
    }
    // // always prefer to refresh if user is not a viewer
    // if (!this.user_is_viewer && !this.user_is_pro) {
    //   return true;
    // }
    if (!this.cognito_tokens.access_token) {
      console.log('Auth refresh due to no access_token')
      return true;
    }
    // refresh token nearing expiry
    if (!access_token_expires) {

      access_token_expires = await this.get_access_token_expires(this.cognito_tokens.access_token)

    }
    if (!access_token_expires){
      console.log('Auth refresh due to expired access_token')
    }else if((((access_token_expires * 1000) - Date.now()) <= 1000 * 60)) { // refresh if less than 1 hr remaining before access_token expires
      console.log('Auth refresh due to access_token nearing expiration: ', access_token_expires * 1000, Date.now(),(access_token_expires * 1000) - Date.now() )
    }

    return (!access_token_expires || (((access_token_expires * 1000) - Date.now()) <= 1000 * 60 * 7));
  }


  /**
   * Request that login be processed (either by loading tokens from localStorage / cookies or by prompting user to authenticate)
   * @return {Promise<void>}
   */
  async request_auth() {
    // check if user authed via portal
    if ((localStorage && localStorage.getItem('esri_ident')) || (sessionStorage && sessionStorage.getItem('esri_ident'))) {
      return await this.auth_flow_portal_login_start()
    }

    // attempt to use refresh token to get a new access_token as needed, otherwise clear expired tokens
    if (!!this.cognito_tokens && !!this.cognito_tokens.access_token) {
      if (await this.needs_refresh()) {
        await this.auth_flow_cognito_login_refresh(this.cognito_tokens.refresh_token, 'clear')
      } else {
        // this.cognito_tokens = null;
      }
    }
    if (!!this.cognito_tokens && !!this.cognito_tokens.access_token && !!this.cognito_tokens.id_token) {
      this._update_user_attributes(jose.UnverifiedJWT.decode(this.cognito_tokens.id_token)['payload'], jose.UnverifiedJWT.decode(this.cognito_tokens.access_token)['payload'])
      if ((this.project_id && this.user_is_viewer) || this.user_is_pro) {
        this._hide_dialogs();
        this._resolve_when_auth();
      } else {
        this.show_request_access_dialog();
      }
    } else {
      this.show_auth_flow_dialog();
    }
    // schedule watcher to check if we need to refresh every ~45 seconds.
    if (!this.refresh_watcher) {
      this.refresh_watcher = setInterval(this._check_refresh.bind(this), 45000)
    }

    return this.when_authed;
  }

  show_auth_flow_dialog() {
    if (!!this.auth_request_access_dialog) {
      this.auth_request_access_dialog.current.hide();
    }
    if (!this.auth_flow_dialog) {
      this.auth_flow_dialog = createRef();
      render(html`
        <${AuthFlowDialog} ref=${this.auth_flow_dialog}/>`, document.body)
    }
    if (!!this.auth_request_access_dialog) {
      this.auth_request_access_dialog.current.hide();
    }
    this.auth_flow_dialog.current.request_show();
  }

  show_request_access_dialog(invite_code) {
    if (!this.auth_request_access_dialog) {
      this.auth_request_access_dialog = createRef();

      console.log("Creating auth_request_access_dialog ref")

      render(html`
        <${AuthFlowDialog} ref=${this.auth_request_access_dialog} />`, document.body);
    }
    if (!!this.auth_request_access_dialog) {
      this.auth_request_access_dialog.current.hide()
    }

    console.log(this.auth_request_access_dialog, invite_code);

    this.auth_request_access_dialog.current.request_show(invite_code);
  }

  /**
   * Attempt to refresh access_token and cloudfront tokens using refresh_token.
   * @param refresh_token The refresh token, or null to
   * @param fail_behavior {string} 'logout', 'clear', or 'ignore'
   * @return {Promise<boolean>}
   */
  async auth_flow_cognito_login_refresh(refresh_token = null, fail_behavior = 'logout') {
    if (!refresh_token && !!this.cognito_tokens && 'refresh_token' in this.cognito_tokens) {
      refresh_token = this.cognito_tokens.refresh_token
    }
    let can_access = false;
    if (!!refresh_token) {
      await this._fetch_cognito_tokens(null, null, this.cognito_tokens.refresh_token)
      const can_access_response = await (fetch(`${this.application_api_url}/auth`, {
        method: 'POST',
        body: JSON.stringify({
          "access_token": this.cognito_tokens.access_token,
          "include_cloudfront_tokens": this.project_id
        }),
        cors: true,
        credentials: 'include'
      }).catch(AuthManager._access_denied_response));
      can_access = can_access_response.status === 200;
      for (const c of (await can_access_response.json())['cf_cookies']) {
        document.cookie = this._filter_cookie(c)
      }

      console && console.log('Refreshed token successfully')
    }
    this._save_tokens();
    if (can_access) {
      // resolve when_auth as needed.
      if (!!this._when_auth) {
        this._hide_dialogs();
        this._resolve_when_auth()
      }
      return true
    } else {
      if (fail_behavior === 'clear') {
        this.cognito_tokens = null;
      }
      if (fail_behavior === 'logout') {
        this.cognito_tokens = null;
        // noinspection ES6MissingAwait
        this.request_auth();
      }
      this._save_tokens();
      return false;
    }

  }

  // noinspection JSUnusedGlobalSymbols
  /**
   * Opens OAuth login/signup page in a new window.
   *
   * @param signup bool
   */
  async auth_flow_cognito_login_start(signup) {
    this.auth_flow_dialog?.current?.setState({error_message: null});

    this.auth_flow = 'cognito';
    this.oauth_window_status_watcher = null;
    this.when_authorization_code = new Promise((resolve) => {
      this._resolve_authorization_code = resolve;
    });
    this.when_authorization_code.then(() => {
      if (this.oauth_window_status_watcher) {
        window.clearInterval(this.oauth_window_status_watcher);
      }
    });
    if (!this.oauth_popup_window) {
      const h = 650;
      const w = 830;
      const y = window.top.outerHeight / 2 + window.top.screenY - (h / 2);
      const x = window.top.outerWidth / 2 + window.top.screenX - (w / 2);
      // open about:blank immediately so the window isn't blocked after we await while generating the challenge_code
      this.oauth_popup_window = window.open('about:blank', 'acceladapt_oauth', `copyhistory=no,width=${w},height=${h},top=${y},left=${x},resizable=yes,scrollbars=yes,status=no,toolbar=yes,menubar=yes`);
      // prevent orphaned oauth dialogs.
      window.addEventListener('onbeforeunload', () => {
        try {
          if (this.oauth_popup_window) {
            this.oauth_popup_window.close()
          }
        } catch {
          //do nothing
        }
      });
      if (!this.oauth_window_status_watcher) {
        this.oauth_window_status_watcher = window.setInterval(this._check_oauth_popup_window_status.bind(this), 1500);
      }
      // Create random "state", used to make CSRF more difficult / mitigate man in the middle attacks.
      this.oauth_state = AuthManager._generate_random_key();

      // Create PKCE code verifier, used to verify that this client is the same machine across multiple requests to the OAuth backend.
      // const code_verifier = AuthManager._generate_random_key();
      // sessionStorage.setItem("code_verifier", code_verifier);
      this.code_verifier = AuthManager._generate_random_key();

      // Create code challenge
      const oauth_code_challenge = AuthManager._bash64_url_encode_hash(await AuthManager._sha256_hash(this.code_verifier));
      this.oauth_popup_window.location = `${this.auth_url}/${signup ? 'signup' : 'login'}?client_id=${this.application_client_id}&response_type=code&scope=openid+email+phone+profile&state=${this.oauth_state}&code_challenge_method=S256&code_challenge=${oauth_code_challenge}&redirect_uri=${encodeURIComponent(this.application_url + '/oauth-callback.html')}`
    }
    try {
      this.oauth_popup_window.focus();
    } catch (ex) {
      this.auth_flow_dialog.current.state.error_message = 'Something went wrong when displaying the login form! Please enable popups for this site.'
    }
    const authorization_code_response = await this.when_authorization_code
    if (this.oauth_window_status_watcher) {
      window.clearInterval(this.oauth_window_status_watcher);
      this.oauth_window_status_watcher = null;
    }

    console.log("authorization_code_response:", authorization_code_response);
    console.log("authorization_code_response:", authorization_code_response);

    await (this.fetch_tokens(authorization_code_response, this.invite_code)).catch((e) => {
      this.auth_flow_dialog.current.state.error_message = "Something went wrong. Please try again."
      this.auth_flow_dialog.current.request_show();
      console.warn(e);
    });

  }

  /**
   * Start the ArcGIS-based login flow.
   * @return {Promise<void>}
   */
  async auth_flow_portal_login_start() {
    this.auth_flow_dialog?.current?.setState({error_message: null});
    this.auth_flow = 'portal';
    if (!this.portal_manager) {
      // this.portal_manager = new ((await import('./portal_manager.js'))['default'])(this.portal_params)
    }
    this.portal_manager.portal_loaded.catch(() => {
      this.show_auth_flow_dialog();
    }).then(() => {
      console && console.log('Portal login complete');
      this._hide_dialogs();
      this._resolve_when_auth();
    });
  }

  /**
   * Exchanges authorization_code for access token and (when possible) cloudfront cookie token.
   * @param {object|null} oauth
   * @param {string|null} oauth.authorization_code
   * @param {string|null} oauth.oauth_state
   * @param {string|boolean|null} invite_code 'request' to request access, otherwise an invite code.
   * @return {Promise<void>}
   */
  async fetch_tokens({authorization_code = null, oauth_state = null} = {}, invite_code = null) {
    if (this.oauth_popup_window) {
      this.oauth_popup_window.close();
    }
    if (!authorization_code && !this.cognito_tokens) {
      throw new Error('No authorization_code found.');
    }
    if (!this.cognito_tokens) {
      // exchange authorization_code for access_token and refresh_token
      await this._fetch_cognito_tokens(authorization_code, oauth_state);
    }

    console.log("project_id:", this.project_id);

    // then check if user can access the project
    const can_access_response = await (fetch(`${this.application_api_url}/auth`, {
      method: 'POST',
      body: JSON.stringify({
        access_token: this.cognito_tokens.access_token,
        invite_code: invite_code,
        include_cloudfront_tokens: this.project_id
      }),
      cors: true,
      credentials: 'include'
    }).catch(AuthManager._access_denied_response));
    this._save_tokens();
    if (!!can_access_response && can_access_response.status === 200) {
      if (!!invite_code && invite_code !== 'request') {
        // Immediately refresh tokens if we just used an invite code
        this.invite_code = null;
        console && console.log('Invite accepted successfully, refreshing tokens.');
        await this.auth_flow_cognito_login_refresh();
        return
      }
      for (const c of (await can_access_response.json())['cf_cookies']) {
        document.cookie = this._filter_cookie(c)
      }


      console && console.log('Cognito login complete');
      this._hide_dialogs();
      this._resolve_when_auth();
    } else {
      if (!this.auth_request_access_dialog) {
        this.auth_request_access_dialog = new AuthRequestAccessDialog(window.app);
      }
      if (!!this.auth_flow_dialog) {
        this.auth_flow_dialog.current.hide()
      }
      this.auth_request_access_dialog.request_show(!!invite_code && invite_code !== 'request');

    }
  }


  /**
   * Validates the access token and returns its expiration time. Returns null for invalid / expired.
   * @return {Promise<null>}
   */
  async get_access_token_expires(access_token = null) {
    let access_token_expires = null;
    if (!!access_token) {
      const [access_token_valid, access_claims] = await AuthManager._verify_cognito_JWT_token(access_token, this.application_user_pool_id, null, this.application_user_pool_region)
      if (access_token_valid) {
        access_token_expires = access_claims['exp']
      }
    }
    return access_token_expires
  }

  /**
   * Submits a new invite request for the current user.
   * @param invite_code
   * @return {Promise<void>}
   */
  async request_access(invite_code) {
    return await this.fetch_tokens({}, invite_code)
  }

  logout(no_redirect = false) {
    if (this.portal_manager) {
      this.portal_manager.logout(true)
    }
    // clear all cookies
    document.cookie.split(";").forEach(function (c) {
      document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
    //clear local storage
    try {
      if (typeof (localStorage) !== "undefined") {
        localStorage.clear();
      }
    } catch (ex) {
      // do nothing
    }

    try {
      if (typeof (sessionStorage) !== "undefined") {
        sessionStorage.clear();
      }
    } catch (ex) {
      // do nothing
    }
    if (!no_redirect) {
      document.location = `/?b=${Math.floor(Math.random() * Math.floor(100000))}`
    }
  }

  //
  // private instance methods
  //

  /**
   * Detect if the oauth window closes unexpectedly and re-show the beginning of the auth flow.
   */
  _check_oauth_popup_window_status() {
    if (!this.oauth_popup_window || this.oauth_popup_window.closed) {
      if (!!this.oauth_popup_window) {
        this.oauth_popup_window.close();
      }
      this.oauth_popup_window = null;
      this.auth_flow_dialog.current.setState({error_message: 'The login form was closed, please try again.'})
      this.auth_flow_dialog.current.request_show();
      window.clearInterval(this.oauth_window_status_watcher);
    }
  }

  _load_tokens() {
    try {
      if (localStorage) {
        let item = localStorage.getItem('acceladapt_tokens');
        if (item) {
          this.cognito_tokens = JSON.parse(item);
        }
      }
    } catch (ex) {
      //do nothing
    }
    if (!this.cognito_tokens) {
      let tokens_cookie = document.cookie.split('; ').reduce((r, v) => {
        const parts = v.split('=');
        return parts[0] === 'acceladapt_tokens' ? decodeURIComponent(parts[1]) : r;
      }, '');
      if (tokens_cookie) {
        this.cognito_tokens = JSON.parse(tokens_cookie);
      }
    }
  }


  _save_tokens() {
    if (localStorage) {
      localStorage.setItem('acceladapt_tokens', JSON.stringify(this.cognito_tokens));
    } else if (sessionStorage) {
      sessionStorage.setItem('acceladapt_tokens', JSON.stringify(this.cognito_tokens));
    } else {
      document.cookie = 'acceladapt_tokens' + '=' + encodeURIComponent(JSON.stringify(this.cognito_tokens)) + ';';
    }
  }

  _update_user_attributes(id_token_payload, access_token_payload) {
    const _user_attributes = {};
    if (!!id_token_payload) {
      _user_attributes.username = 'cognito:username' in id_token_payload ? id_token_payload['cognito:username'] : null
      _user_attributes.sub = 'sub' in id_token_payload ? id_token_payload['sub'] : null
      _user_attributes.user_email = 'email' in id_token_payload ? id_token_payload['email'] : null
      _user_attributes.user_nicename = 'given_name' in id_token_payload ? id_token_payload['given_name'] : null
    }
    if (!!access_token_payload && 'cognito:groups' in access_token_payload) {
      const cognito_groups = access_token_payload['cognito:groups'] || [];
      for (const group of ['viewer', 'manager']) {
        if (cognito_groups.includes(this.project_id + '_' + group)) {
          _user_attributes['user_is_' + group] = true;
          window.document.body.classList.add(group)
        } else {
          _user_attributes['user_is_' + group] = false;
          window.document.body.classList.remove(group)
        }
      }
      if (cognito_groups.includes('pro')) {
        _user_attributes['user_is_pro'] = true;
        window.document.body.classList.add('pro')
      } else {
        _user_attributes['user_is_pro'] = false;
        window.document.body.classList.remove('pro')
      }
    }
    this._user_attributes = _user_attributes
  }

  /**
   * Exchanges authorization_code or refresh_token for a fresh access token, id token, and (if using authorization_code) refresh_token
   * @param authorization_code
   * @param oauth_state
   * @param refresh_token
   * @return {Promise<void>}
   * @private
   */
  async _fetch_cognito_tokens(authorization_code = null, oauth_state = null, refresh_token = null) {
    let authorization_code_grant_params = {};
    let refresh_token_grant_params = {}
    if (!authorization_code && !refresh_token) {
      throw new Error('No authorization_code or refresh_token provided.')
    }
    if (!refresh_token) {
      if (oauth_state !== this.oauth_state) {
        throw new Error('Authentication request may have been tampered with. Invalid state.')
      }
      authorization_code_grant_params = {
        code_verifier: this.code_verifier,
        code: authorization_code
      }
    } else {
      refresh_token_grant_params = {
        refresh_token: refresh_token
      }
    }

    // Fetch OAuth2 tokens from Cognito
    const tokens_response = await (fetch(`${this.auth_url}/oauth2/token`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: Object.entries({
        client_id: this.application_client_id,
        grant_type: !refresh_token ? 'authorization_code' : 'refresh_token',
        redirect_uri: this.application_url + '/oauth-callback.html',
        ...authorization_code_grant_params,
        ...refresh_token_grant_params,
      }).map(([k, v]) => k + '=' + encodeURIComponent(v)).join('&')
    }).catch(AuthManager._access_denied_response))
    /** @type {{id_token, access_token, refresh_token}} */
    const tokens = await tokens_response.json()
    // Verify id token and access token
    const [id_token_valid, id_claims] = await AuthManager._verify_cognito_JWT_token(tokens.id_token, this.application_user_pool_id, this.application_client_id, this.application_user_pool_region)
    if (!!id_token_valid) {
      const [access_token_valid, access_claims] = await AuthManager._verify_cognito_JWT_token(tokens.access_token, this.application_user_pool_id, null, this.application_user_pool_region);
      // merge fresh tokens into existing tokens (specifically to preserve the refresh_token if it is not returned after use)
      if (!!access_token_valid) {
        if (!this.cognito_tokens) {
          this.cognito_tokens = {};
        }
        for (const k of ['access_token', 'id_token', 'refresh_token']) {
          if (k in tokens) {
            this.cognito_tokens[k] = tokens[k]
          }
        }

        this._update_user_attributes(id_claims, access_claims);
        // invoke auth_callbacks
        this.auth_callbacks.forEach(a=>typeof a === "function" && a())
      } else {
        throw new Error("Invalid ID Token");
      }
    }
  }

  async _check_refresh() {
    if (await this.needs_refresh()) {
      return await this.auth_flow_cognito_login_refresh(null, !!this.auth_request_access_dialog && this.auth_request_access_dialog.visible ? 'ignore' : 'logout')
    }
  }

  _hide_dialogs() {
    if (!!this.auth_flow_dialog) {
      this.auth_flow_dialog.current.hide()
    }
    if (!!this.auth_request_access_dialog) {
      this.auth_request_access_dialog.current.hide()
    }
  }

  /**
   * Registers a function to be called whenever the cognito tokens and/or user attributes have been updated.
   * @param fn
   */
  register_auth_callback(fn) {
    this.auth_callbacks.push(() => {
      try {
        fn();
      } catch (ex) {
        console.log('Error while processing auth_callback:', ex)
      }
    });
  }

  //
  // private static methods
  //

  /**
   * Fetches the public keys for the requested user pool.
   * @param user_pool_id
   * @param user_pool_region
   * @return {Promise<*>}
   * @private
   */
  static async _fetch_cognito_public_keys(user_pool_id, user_pool_region) {
    if (!(user_pool_id in _cognito_public_keys)) {
      _cognito_public_keys[user_pool_id] = (await (await (fetch('https://cognito-idp.' + user_pool_region + '.amazonaws.com/' + user_pool_id + '/.well-known/jwks.json').catch(AuthManager._access_denied_response))).json())['keys']
    }
    return _cognito_public_keys[user_pool_id]
  }

  /**
   * Verifies cognito access, id, and refresh tokens
   * @param token
   * @param user_pool_id
   * @param client_id
   * @param user_pool_region
   * @return {Promise<(boolean|*)[]|boolean[]>}
   * @private
   */
  static async _verify_cognito_JWT_token(token, user_pool_id, client_id = null, user_pool_region = 'us-east-1') {
    //get Cognito public keys
    try {
      const well_known_keys = await AuthManager._fetch_cognito_public_keys(user_pool_id, user_pool_region)

      //Get the kid (key id) and check that token isn't expired
      const key_id = jose.UnverifiedJWT.decode(token)['header']['kid']

      //search for the kid key id in the Cognito Keys
      const key = well_known_keys.find(key => key.kid === key_id)
      if (!key) {
        throw new Error("Public key not found in Cognito jwks.json");
      }
      const publicKey = await jose.parseJwk(key)
      let {payload} = await jose.jwtVerify(token, publicKey, {
        issuer: `https://cognito-idp.${user_pool_region}.amazonaws.com/${user_pool_id}`,
        audience: client_id
      })

      return [true, payload]; //verified
    } catch (ex) {
      if (ex.message !== "\"exp\" claim timestamp check failed") {
        console && console.error(ex)
      }
      return [false, null]
    }
  }

  /**
   * Convert Hash to Base64-URL
   * @param chars
   * @return {string}
   */
  static _bash64_url_encode_hash(chars) {
    const _string = new Uint8Array(chars).reduce((acc, i) => `${acc}${String.fromCharCode(i)}`, '')
    return btoa(_string).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '')
  }


  /**
   * SHA256 string
   * @param str
   * @return {Promise<ArrayBuffer>}
   */
  static async _sha256_hash(str) {
    const textEncoder = new TextEncoder();
    const encodedData = textEncoder.encode(str);
    return crypto.subtle.digest('SHA-256', encodedData);
  }

//Generate a random string
  static _generate_random_key() {

    const CHARSET =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
    const buffer = new Uint8Array(128);
    if (typeof window !== 'undefined' && !!window.crypto) {
      window.crypto.getRandomValues(buffer);
    } else {
      for (let i = 0; i < 128; i += 1) {
        buffer[i] = (Math.random() * CHARSET.length) | 0;
      }
    }
    return AuthManager._buffer_to_string(buffer);
  }

  static _buffer_to_string(buffer) {
    const CHARSET =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const val = [];
    for (let i = 0; i < buffer.byteLength; i += 1) {
      const index = buffer[i] % CHARSET.length;
      val.push(CHARSET[index]);
    }
    return val.join('');
  }

  static async _access_denied_response(err) {
    console.warn(err);
    return new Response(new Blob(), {
      status: 403,
      statusText: 'Access Denied'
    });
  }
}
